import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppModule } from './app.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/avatar-builder/platform/avatar-builder.module').then(
        (m) => m.AvatarBuilderModule
      ),
  },
  { path: '**', redirectTo: '' },
];

export const ModuleRouting: ModuleWithProviders<AppModule> =
  RouterModule.forRoot(routes, {
    enableTracing: false,
    anchorScrolling: 'enabled',
  });
