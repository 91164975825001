import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Injectable()
export class ScreenWidthService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  isDesktop(): Observable<boolean> {
    return this.breakpointObserver
      .observe(['(min-width: 768px)'])
      .pipe(map((state: BreakpointState) => state.matches));
  }
}
