<div class="main-footer">
  <ul class="widget-list">
    <li class="widget-item">
      <a [href]="imprintLink" target="_blank">{{
        "APP.IMPRINT" | translate
      }}</a>
    </li>

    <li class="widget-item">
      <a [href]="dataPrivacyLink" target="_blank">{{
        "APP.DATA_PRIVACY" | translate
      }}</a>
    </li>
  </ul>

  <div class="language-icons">
    <div class="language-icon" (click)="changeLanguage('de')">
      <img
        class="media-object header-logo"
        src="./assets/i18n/de_DE.png"
        alt="Echom AI - German"
      />
    </div>
    <div class="language-icon" (click)="changeLanguage('en')">
      <img
        class="media-object header-logo"
        src="./assets/i18n/en_US.png"
        alt="Echom AI - English"
      />
    </div>
  </div>
</div>
