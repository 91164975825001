import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import {
  ClaimAvatarReq,
  CreateAvatarReq,
  AvatarVideoRes,
} from '@models/avatar/avatar-requests.model';

@Injectable()
export class AvatarService {
  private readonly apiPath = `${env.apiAvatarService}`;

  constructor(private api: ApiService) {}

  createAvatar(req: CreateAvatarReq): Observable<AvatarVideoRes> {
    return this.api.post<AvatarVideoRes>(`${this.apiPath}/avatars`, req);
  }

  claimAvatar(avatarId: number, req: ClaimAvatarReq): Observable<void> {
    return this.api.post<void>(
      `${this.apiPath}/avatars/${avatarId}/claim`,
      req
    );
  }

  getAvatarVideo(videoId: string): Observable<AvatarVideoRes> {
    return this.api.get<AvatarVideoRes>(
      `${this.apiPath}/avatar-videos/${videoId}`
    );
  }
}
