import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, map, takeUntil, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-voice-recorder',
  templateUrl: './voice-recorder.component.html',
  styleUrls: ['./voice-recorder.component.scss'],
})
export class VoiceRecorderComponent implements OnInit, OnDestroy {
  //Lets declare Record OBJ
  record!: RecordRTC.StereoAudioRecorder | null;
  stream!: MediaStream | null;
  //Will use this flag for toggeling recording
  recording = false;
  //URL of Blob
  url?: string;
  permissionsError?: string;

  recordingTimer?: string;
  timer: Subscription | null = null;

  @Output() recordingChanged: EventEmitter<string> = new EventEmitter();

  @ViewChild('recordedAudio', { read: ElementRef, static: false })
  recordedAudio!: ElementRef;

  constructor(
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getMicPermissions();
  }

  ngOnDestroy(): void {
    this.stopMedia();
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  /**
   * Start recording.
   */
  startRecording() {
    if (this.recording) {
      this.stopRecording();
      return;
    }

    this.stopRecording();
    this.url = undefined;
    this.recordingChanged.emit(this.url);
    const mediaConstraints = {
      video: false,
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }
  /**
   * Will be called automatically.
   */
  successCallback(stream: MediaStream) {
    this.permissionsError = undefined;
    const options: RecordRTC.Options = {
      type: 'audio',
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      desiredSampRate: 16000,
    };
    this.stream = stream;
    //Start Actuall Recording
    this.recording = true;
    this.record = new RecordRTC.StereoAudioRecorder(this.stream, options);
    this.record.record();
    this.timer = this.getTimer(30).subscribe({
      next: (timer) => {
        this.recordingTimer = timer;
      },
      complete: () => {
        this.stopRecording();
      },
    });
  }
  /**
   * Stop recording.
   */
  stopRecording() {
    if (this.timer) {
      this.timer.unsubscribe();
      this.timer = null;
    }
    this.recording = false;
    this.record?.stop(this.processRecording.bind(this));
    this.stopMedia();
  }
  /**
   * processRecording Do what ever you want with blob
   * @param  {any} blob Blog
   */
  processRecording(blob: Blob) {
    this.url = URL.createObjectURL(blob);

    // emit base64 audio
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      const audioUrl = reader.result?.toString();
      this.recordingChanged.emit(audioUrl);
    };
  }
  /**
   * Process Error.
   */
  errorCallback() {
    this.permissionsError = this.translateService.instant(
      'APP.ERRORS.MIC_PERMISSIONS'
    );
  }

  reset() {
    this.stopRecording();
    this.url = undefined;
    this.recordingChanged.emit(undefined);
  }

  onAudioEnded() {
    if (!this.recordedAudio || !this.recordedAudio.nativeElement) {
      return;
    }

    this.recordedAudio.nativeElement.currentTime = 0;
  }

  private stopMedia() {
    if (this.record) {
      this.record = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach((track) => track.stop());
        this.stream = null;
      }
    }
  }

  private getTimer(seconds: number) {
    let distance = seconds * 1000;

    return timer(0, 1000).pipe(
      takeUntil(timer(distance)),
      map(() => {
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const secondsStr = seconds.toString().padStart(2, '0');
        distance -= 1000;
        return `00:${secondsStr}`;
      })
    );
  }

  private getMicPermissions() {
    const mediaConstraints = {
      video: false,
      audio: true,
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(
      () => {
        this.permissionsError = undefined;
      },
      (err) => {
        this.permissionsError = this.translateService.instant(
          'APP.ERRORS.MIC_PERMISSIONS'
        );
      }
    );
  }
}
