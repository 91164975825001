<div class="buttons-cont" *ngIf="!permissionsError; else noPermissions">
  <ng-container *ngIf="url; else noRecording">
    <button
      class="record-btn play-btn"
      (click)="
        recordedAudio.paused || recordedAudio.ended
          ? recordedAudio.play()
          : recordedAudio.pause()
      "
    >
      <i
        class="fa"
        [ngClass]="
          recordedAudio.paused || recordedAudio.ended ? 'fa-play' : 'fa-pause'
        "
      ></i>
    </button>
    <div class="text-with-icon-cont">
      <i class="fa fa-trash-alt"></i>
      <div class="text-link" (click)="reset()">
        {{ "BUILDER.RE_RECORD_VOICE" | translate }}
      </div>
    </div>

    <audio #recordedAudio id="recordedAudio" (ended)="onAudioEnded()">
      <source [src]="sanitize(url)" type="audio/wav" />
    </audio>
  </ng-container>
  <ng-template #noRecording>
    <button
      class="record-btn"
      (pointerdown)="startRecording()"
      (pointerup)="stopRecording()"
    >
      <i class="fa fa-microphone"></i>
    </button>
    <div class="text-with-icon-cont timer-cont" *ngIf="recording">
      <i class="fa fa-microphone"></i>
      <div>{{ recordingTimer }}</div>
    </div>
  </ng-template>
</div>
<ng-template #noPermissions>
  <div class="permissions-error">{{ permissionsError }}</div>
</ng-template>
