import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent {
  @Input() videoUrl?: string;
  @Input() thumbnailImgSrc?: string;
  showControls = false;

  @ViewChild('videoPlayer', { read: ElementRef, static: false })
  videoPlayer!: ElementRef;

  playVideo() {
    if (!this.videoPlayer || !this.videoPlayer.nativeElement) {
      return;
    }

    this.videoPlayer.nativeElement.play();
    this.showControls = true;
  }
}
