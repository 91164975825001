import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { ScreenWidthService } from '@services/screen-width.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent implements OnDestroy {
  WIDTH = 400;
  HEIGHT = 300;

  @ViewChild('video')
  video!: ElementRef;

  @ViewChild('fileInput') fileInput!: ElementRef;

  videoStream!: MediaStream | null;
  error: any;
  isCaptured: boolean = false;
  cameraInUse = false;

  uploadedImgUrl?: string = '';

  isDesktop = true;
  imageUploaderPlaceholder =
    '/assets/avatar-builder/image-uploader-placeholder.png';

  @Output() imageUploaded: EventEmitter<string> = new EventEmitter();

  constructor(
    private screenWidthService: ScreenWidthService,
    private translateService: TranslateService
  ) {
    this.trackIsDesktopChange();
  }

  ngOnDestroy(): void {
    this.stopMedia();
  }

  onUseCamera() {
    this.cameraInUse = true;
    this.removeCurrent();
    this.setupDevices();
  }

  onSelectFile(event: Event) {
    this.removeCurrent();

    const target = event.target as HTMLInputElement;
    if (target?.files && target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(target.files[0]);

      reader.onload = () => {
        this.uploadedImgUrl = reader.result?.toString();
        this.imageUploaded.emit(this.uploadedImgUrl);
      };
    }
  }

  onContainerUpload() {
    if (
      (this.uploadedImgUrl && this.uploadedImgUrl !== '') ||
      this.cameraInUse
    ) {
      return;
    }
    this.fileInput.nativeElement.click();
  }

  async capture() {
    // MediaStreamTrack
    const mediaStreamTrack = this.videoStream?.getVideoTracks()[0];

    if (mediaStreamTrack) {
      // ImageCapture
      const imageCapture = new ImageCapture(mediaStreamTrack);
      if (imageCapture) {
        // Take picture
        return imageCapture.takePhoto().then((blob) => {
          // Upload photo right away
          if (blob) {
            this.blobToDataURL(blob, (dataUrl: string) => {
              this.uploadedImgUrl = dataUrl;
              this.isCaptured = true;
              this.imageUploaded.emit(this.uploadedImgUrl);
              this.stopMedia();
              this.cameraInUse = false;
            });
          }
        });
      }
    }
  }

  removeCurrent() {
    this.isCaptured = false;
    this.uploadedImgUrl = '';
    this.imageUploaded.emit(undefined);
    this.stopMedia();
  }

  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        this.videoStream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 99999 },
            height: { ideal: 99999 },
          },
        });
        if (this.videoStream) {
          this.video.nativeElement.srcObject = this.videoStream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = this.translateService.instant(
            'APP.ERRORS.NO_VIDEO_DEVICE'
          );
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  blobToDataURL(blob: Blob, callback: Function) {
    var a = new FileReader();
    a.onload = function (e) {
      callback(a.result?.toString());
    };
    a.readAsDataURL(blob);
  }

  private stopMedia() {
    if (this.videoStream) {
      this.videoStream.getVideoTracks().forEach((track) => track.stop());
      this.videoStream = null;
    }
  }

  private trackIsDesktopChange() {
    this.screenWidthService.isDesktop().subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
      if (this.isDesktop) {
        this.imageUploaderPlaceholder =
          '/assets/avatar-builder/image-uploader-placeholder.png';
      } else {
        this.imageUploaderPlaceholder =
          '/assets/avatar-builder/image-uploader-placeholder-mobile.png';
      }
    });
  }
}
