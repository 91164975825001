<div class="video-cont">
  <img
    *ngIf="thumbnailImgSrc && thumbnailImgSrc !== ''"
    [class.display-none]="showControls"
    [src]="thumbnailImgSrc"
  />
  <button
    class="play-btn"
    [class.display-none]="!videoUrl || showControls"
    (click)="playVideo()"
  >
    <i class="fal fa-play"></i>
  </button>
  <video
    *ngIf="videoUrl"
    [class.display-none]="
      !showControls && thumbnailImgSrc && thumbnailImgSrc !== ''
    "
    [attr.controls]="showControls ? '' : null"
    controlslist="nodownload"
    #videoPlayer
  >
    <source [src]="videoUrl" type="video/mp4" />
  </video>
</div>
