<div class="app-content">
  <app-header></app-header>
  <div
    class="sticky-footer"
    [class.lang-en]="lang === 'en'"
    [class.lang-de]="lang === 'de'"
    style="flex: 1 0 auto !important"
  >
    <router-outlet></router-outlet>
  </div>
  <app-footer
    class="app-footer"
    (languageChange)="onLanguageChange($event)"
  ></app-footer>
</div>
