<div class="image-uploader">
  <div
    class="picture-preview-cont"
    [style.background-image]="
      uploadedImgUrl && uploadedImgUrl !== ''
        ? 'url(' + uploadedImgUrl + ')'
        : 'url(' + imageUploaderPlaceholder + ')'
    "
    [class.preview-border]="uploadedImgUrl && uploadedImgUrl !== ''"
    [class.uploader]="
      (!uploadedImgUrl || uploadedImgUrl === '') && !cameraInUse
    "
    (click)="onContainerUpload()"
  >
    <div
      class="upload-btn"
      [class.display-none]="
        (uploadedImgUrl && uploadedImgUrl !== '') || cameraInUse
      "
    >
      <i class="fal fa-plus"></i>
    </div>
    <video
      [class.display-none]="isCaptured || !cameraInUse"
      #video
      id="video"
      [width]="WIDTH"
      [height]="HEIGHT"
      autoplay
    ></video>
  </div>
  <div class="buttons-cont mobile-only">
    <div
      *ngIf="uploadedImgUrl && uploadedImgUrl !== ''"
      class="text-link"
      (click)="fileInput.click()"
    >
      {{ "BUILDER.REDO_PICTURE" | translate }}
    </div>
  </div>
  <div class="buttons-cont desktop-only">
    <button
      class="btn btn-primary-rounded"
      *ngIf="cameraInUse && !isCaptured"
      (click)="capture()"
    >
      {{ "BUILDER.SNAP_PHOTO" | translate }}
    </button>
    <ng-container *ngIf="!cameraInUse">
      <button class="btn btn-primary-rounded" (click)="onUseCamera()">
        {{ "BUILDER.USE_CAMERA" | translate }}
      </button>
      <button class="btn btn-black-rounded" (click)="fileInput.click()">
        {{ "BUILDER.UPLOAD_PICTURE" | translate }}
      </button>
    </ng-container>
    <input
      class="display-none"
      #fileInput
      id="fileInput"
      type="file"
      accept="image/*"
      (change)="onSelectFile($event)"
    />
  </div>
</div>
