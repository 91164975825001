import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-success-circle',
  templateUrl: './success-circle.component.html',
  styleUrls: ['./success-circle.component.scss'],
})
export class SuccessCircleComponent {
  @Input() size: number = 60;
}
