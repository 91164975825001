import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';

import { ImageUploaderComponent } from '../components/image-uploader/image-uploader.component';
import { VoiceRecorderComponent } from '../components/voice-recorder/voice-recorder.component';
import { SuccessCircleComponent } from '../components/success-circle/success-circle.component';
import { VideoPlayerComponent } from '../components/video-player/video-player.component';

const modules = [
  CommonModule,
  HttpClientModule,
  FormsModule,
  MatFormFieldModule,
  ReactiveFormsModule,
  MatInputModule,
  MatCheckboxModule,
  TranslateModule,
];

const components = [
  ImageUploaderComponent,
  VoiceRecorderComponent,
  SuccessCircleComponent,
  VideoPlayerComponent,
];

@NgModule({
  imports: [RouterModule, modules],
  exports: [modules, components],
  declarations: [components],
})
export class SharedModule {}
