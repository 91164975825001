import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'echom-ai-front';
  lang: string;

  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'de']);
    this.lang = this.translate.currentLang;
  }

  onLanguageChange(lang: string) {
    this.translate.use(lang).subscribe(() => {
      this.lang = this.translate.currentLang;
    });
  }
}
