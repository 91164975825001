import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  imprintLink = 'https://echom.ai/en/imprint/';
  dataPrivacyLink = 'https://echom.ai/en/data-privacy/';

  @Output() languageChange: EventEmitter<string> = new EventEmitter();

  changeLanguage(lang: string) {
    this.languageChange.emit(lang);
  }
}
