<nav class="navbar">
  <div class="container header-container xl-container">
    <a class="navbar-brand" routerLink="/">
      <img
        class="media-object header-logo"
        src="./assets/logo.png"
        alt="Echom AI - Logo"
      />
    </a>
    <div class="beta-header">Beta</div>
  </div>
</nav>
